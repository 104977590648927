<header class="main-header" id="header">
  <div class="sb-nav-fixed me-3">
    <nav class="navbar navbar-light header-bottom">

      <div class="col-md-3 p-3 m-0 dateView">
        <i-feather name="menu" (click)="toggleSidebar()" class="cursor-pointer"></i-feather>
      </div>
      <div class="d-flex">


        <div class="p-0 m-3">
          <i-feather id="iconBell" name="bell" (click)="openNotificationDialog()" class="cursor-pointer"></i-feather>

        </div>

        <div class="navbar-nav header-right">
          <li class="nav-item dropdown header-profile">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="header-info mx-2">
                <span class="text-black text-uppercase">{{ user?.name ?? " No account" }}</span><br>
                <span class="text-black text-uppercase">{{ user?.empId }}</span>
                <p class="mb-0 text-muted">{{ user?.designation| uppercase }}</p>
              </div>
              <div class="circle">
              <span *ngIf="user?.profilePicture" class="initials">
<!--                {{ user?.name ? getInitials(user?.name) : '' }}-->
                <img class="image" src="{{user?.profilePicture??'https://img-cdn.pixlr.com/image-generator/history/65bb506dcb310754719cf81f/ede935de-1138-4f66-8ed7-44bd16efc709/medium.webp'}}">
              </span>
                <span *ngIf="!user?.profilePicture" class="initials">
                {{ user?.name ? getInitials(user?.name) : '' }}
              </span>

              </div>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" (click)="logout()">
                <span><i class="fas fa-sign-out-alt mx-2"></i>Logout</span>
              </a></li>
            </ul>
          </li>
        </div>
      </div>
    </nav>
  </div>
</header>

