import { Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { DataService } from "../services/data.service";
import { LoginService } from "../services/login.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SessionManager } from "../Shared/SessionManager";
import { Router } from "@angular/router";
import { NgxOtpBehavior, NgxOtpInputConfig } from "ngx-otp-input";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    otpInputConfig: NgxOtpInputConfig = {
        otpLength: 6,
        autofocus: true,
        autoblur: true,
        isPasswordInput: false,
        behavior: NgxOtpBehavior.DEFAULT,
        classList: {
            container: 'my-super-container',
            inputBox: 'my-super-box-class',
            input: ['my-super-input-class', 'my-super-input-class-array-test'],
            inputFilled: 'my-super-filled-class',
            inputDisabled: 'my-super-disable-class',
            inputSuccess: 'my-super-success-class',
            inputError: 'my-super-error-class',
        },
    };

    loginType: FormGroup | any;
    formMobile: FormGroup | any;
    formOtp: FormGroup | any;
    showLoader = false;
    mobile = '';
    OTP = '';
    nonce = '';
    type = '';
    visible1 = true;
    visible2 = false;
    visible3 = false;
    errorMessage = '';
    sendingOtp: boolean = false;
    validatingOtp: boolean = false;
    loginError: string = '';

    constructor(
        private dataService: DataService,
        private service: LoginService,
        private fb: FormBuilder,
        private router: Router,
        public snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.loginType = this.fb.group({
            type: [''],
        });
        this.formMobile = this.fb.group({
            mobile: [''],
            deviceId: ['device#2']
        });
        this.formOtp = this.fb.group({
            otp: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
        });

        if (SessionManager.getToken()) {
          if (SessionManager.getUser().roleName == 'employee') {
            this.showLoader = false;
            this.router.navigate(['/user/dashboard']);
          } else if (SessionManager.getUser().roleName == 'admin') {
            this.router.navigate(['/admin/dashboard']);
          }
        }
    }

    reEnteredNumber(): void {
      this.formOtp.get('otp').setValue("");
      this.loginError = '';
      this.visible3 = false;
      this.visible2 = true;
    }

    getLoginType(): void {
      this.dataService.isLoading.next(true);
      this.dataService.loggedInUser.next(this.loginType.get('type').value);
      this.visible1 = false;
      this.visible2 = true;
      this.dataService.isLoading.next(false);
    }

    getLoginOtp(): void {
      this.dataService.isLoading.next(true);
      this.sendingOtp = true;
      this.showLoader = true;
      if (this.formMobile.invalid) {
          this.dataService.isLoading.next(false);
          return;
      } else {
          this.mobile = this.formMobile.get('mobile').value;
          this.service.getOtp(this.formMobile.value).subscribe({
              next: res => {
                this.nonce = res.nonce;
                this.type = res.type;
                this.visible2 = false;
                this.visible3 = true;
                this.dataService.isLoading.next(false);
              },
              error: err => {
                this.errorMessage = err.message;
                if (err.status == 0) {
                  this.showLoader = false;
                  this.openSnackBar('Please connect with you internet', 'OK')
                  this.errorMessage = 'Please connect with your internet';
                }
                this.sendingOtp = false;
              },
              complete: () => {
                this.showLoader = false;
                this.sendingOtp = false;
              }
          });
      }
    }

    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 5000,
      });
    }

    get otp() {
      return this.formOtp.get('otp');
    }

    login(): void {
      this.dataService.isLoading.next(true);
      this.showLoader = true;
      this.validatingOtp = true;
      const otpValue =this.formOtp.value.otp;
      if (this.formOtp.invalid) {
        console.error("Invalid");
        return;
      }
      this.service.login({
        mobile: this.mobile,
        otp: otpValue,
        nonce: this.nonce
      }).subscribe({
        next: res => {
            SessionManager.createSession(
                res.accessToken,
                res.id,
                res.refreshToken,
                res.firstName + '' + res.lastName,
                res.userName,
                this.dataService.loggedInUser.value
            );

            this.dataService.empProfile$.subscribe({
                next: (user: any) => {
                    console.log("#135", user);
                    if (this.dataService.loggedInUser.value == 'employee') {
                        this.router.navigate(['user/dashboard']);
                    } else if (this.dataService.loggedInUser.value == 'admin') {
                        this.router.navigate(['admin/dashboard']);
                    }
                    this.dataService.isLoading.next(false);
                },
                error: (err: any) => {
                    this.loginError = err.error.reason;
                    SessionManager.createSession('', '', '', '', '', this.dataService.loggedInUser.value);
                }
            });
        },
        error: err => {
          this.validatingOtp = false;
          this.errorMessage = err.message;
          console.error("err", err);
          this.openSnackBar(err.error.message, 'OK');
        }
      });
    }
}
