<div  class=" mt-4 mx-2">
<!--  <span mat-dialog-title class="mt-0"><strong>Notification Feeds</strong></span>-->
  <h1 mat-dialog-title>Notification Feeds</h1>

  <mat-dialog-content>
        <mat-list>
            <div *ngIf="(notificationsData.data?.length||0) <= 0" class="m-4 text-center p-5">
                      {{notificationsData.networkState == 'Loading'?   'Loading...' :'There is no data.'}}
            </div>
            <div *ngFor="let notification of notificationsData.data"
                class="matListItem">
              <mat-list-item >

                  <div class="flex-fill me-3">
                    <div class="d-flex">
                      <div class="me-3">
                        <i-feather name="bell" class="icon"></i-feather>
                      </div>
                    <p class="mt-1 w-100">{{notification.content}}</p>
                    </div>
                    <div class="d-flex justify-content-end mt-0 pt-0 fs-7 text-secondary" ><small>{{getDate(notification.createdDate)}}</small></div>
                  </div>
              </mat-list-item>
            </div>
        </mat-list>

  </mat-dialog-content>

</div>

