import {KeycloakService} from "keycloak-angular";


export  function initializeKeycloak(keycloak: KeycloakService) :()=>Promise<boolean>{
  return () =>
    keycloak.init({
      config: {
        url: 'http://localhost:10010',
        realm: 'nerthus',
        clientId: 'blog'
      },
      initOptions: {

        pkceMethod: 'S256',
        redirectUri: 'http://localhost:4200/your_url',
        checkLoginIframe: false
      }});
}
