import { Injectable } from '@angular/core';
import {
  CanMatch,
  Router,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {SessionManager} from "../Shared/SessionManager";

@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard implements CanMatch {
  constructor(private router: Router) {}

  canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(SessionManager.getUser().roleName == 'employee' && SessionManager.getToken()) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false
    }
  }
}
