import {AfterContentInit, AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit{
  @Input() public autofocus: boolean | any;

  public constructor(private el: ElementRef) {


  }
  public ngAfterViewInit(): void {
    setTimeout( () => {
      this.el.nativeElement.focus();
    }, 500 );
  }

}
