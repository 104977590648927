import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, tap} from "rxjs";
import {EmployeeProfile, LoggedInUser} from "../Shared/Interfaces";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public isLoading=new BehaviorSubject<boolean>(false);
  public isConfirmation = new BehaviorSubject<boolean>(false);
  public loggedInUser = new BehaviorSubject<LoggedInUser>(<LoggedInUser>'');
  public employeeProfile = new Object(<EmployeeProfile>{});
  empProfile  = new BehaviorSubject<string|null>(null);

  constructor(private service: ApiService) { }

  get empProfile$(): Observable<string|null> {
    return this.empProfile.getValue()
    ? this.empProfile.asObservable()
      : this.getEmpIdFromDB();
  }

  getEmpIdFromDB(): Observable<string|null> {
    return this.service.getRxEmployeeProfile()
      .pipe(
        tap((_result: any) =>{
          this.empProfile.next(_result);
        })
      )
  }

  dialogConfig(data:any):any{
    return {
      width: data.width ?? "",
      height: data.height ?? "",
      maxHeight: data.maxHeight ?? "",
      disableClose : data.disableClose ?? false,
      data,
      animation:{
        to:"left",
        incomingOptions:{
          keyframeAnimationOptions:{duration: 400}
        },
      },
      position:{
        rowEnd: "40%",
      }
    }
  }
  dialogConfigSide(data: any): any {
    return {
      width: data.width ?? '',
      height: data.height ?? '',
      maxHeight: data.maxHeight ?? '',
      disableClose: data.disableClose ?? false,
      data,
      animation: {
        to: 'right',
        incomingOptions: {
          keyframeAnimationOptions: { duration: 400 }
        },
      },
      position: {
        right: '0', // Change to right corner
      }
    };
  }
}
