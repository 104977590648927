export enum AccountType {
  Bonus="Bonus", Loan="Loan", Leave="Leave", CompOff="CompOff",Expense='Expense'
}
export type StatInfo = {
  currentBalance: number,
  type: AccountType
  id?:string
}

export enum NetworkState {
  NotLoaded="NotLoaded", Loading='Loading', Loaded='Loaded', Error= 'Error'
}

export class DataResponse<T> {
  networkState: NetworkState = NetworkState.NotLoaded;
  data?: T;
}
