export const environment = {
  production: false,
  url: 'https://api.carvia-test.org',
  // url: 'http://localhost:8080',
  lfyAuth: 'https://api.carvia-test.org/listingfy-auth',

  // url: 'https://dapi.carvia.tech',
  // baseUrl: 'https://dapi.carvia.tech/lfy-service',
  // lfyAuth: 'https://dapi.carvia.tech/listingfy-auth',

  basicAuth: 'YWNtZTphY21lc2VjcmV0'
};
