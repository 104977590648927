import { Component, OnInit } from '@angular/core';
import { DataResponse, NetworkState } from '../../user-dashboard/AccountType';
import { ApiService } from '../../../services/api.service';
import { DataService } from '../../../services/data.service';
import moment from 'moment';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.css']
})
export class UserNotificationsComponent implements OnInit {
  notificationsData: DataResponse<any[]> = new DataResponse<any[]>();
  showLoader: boolean = false;

  constructor(private apiService: ApiService, private dataService: DataService) {}

  ngOnInit() {
    this.getNotifications();
  }

  getDate(time: any): string {
    const current = Date.now();
    const difference = Math.floor((current - time) / 1000);
    if (difference < 60) {
      return `${Math.floor(difference)} sec ago`;
    } else if (difference < 3600) {
      return `${Math.floor(difference / 60)} min ago`;
    } else if (difference < 86400) {
      return `${Math.floor(difference / 3600)} h ago`;
    } else {
      return moment(time).format('Do MMM YYYY');
    }
  }

  getNotifications(): void {
    this.notificationsData.networkState = NetworkState.Loading;
    this.showLoader = true;
    this.dataService.isLoading.next(true);

    this.apiService.getEmployeeNotificationFeeds().subscribe({
      next: (res) => {
        this.notificationsData.networkState = NetworkState.Loaded;
      console.log( 'net',this.notificationsData.networkState);
        this.notificationsData.data = res.content;
        // console.log( 'net1',res);
        console.log( 'net1', this.notificationsData.data);
        this.showLoader = false;
      },
      error: (err) => {
        this.notificationsData.networkState = NetworkState.Error;
        this.showLoader = false;
      },
      complete: () => {
        this.dataService.isLoading.next(false);

      }
    });
  }
}
