export const Constants={
  storageKeys:{
    UserId: "userId",
    IsLoggedIn: "isLoggedIn",
    AccessToken: "accessToken",
    RefreshToken: "refreshToken",
    UserRole: "userRole",
    SessionUser: "sessionUser"
  },
}
