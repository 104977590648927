import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {SessionManager} from "../../Shared/SessionManager";
import {DataService} from "../../services/data.service";
import {ApiService} from "../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {UserNotificationsComponent} from "../../user/components/user-notifications/user-notifications.component";
import {end, right} from "@popperjs/core";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  toggle = true;
  role: string | undefined;
  curDate: Date | undefined;
  @Input() user: any | undefined;
  @Output() sidebarClass = new EventEmitter<boolean>();

  constructor(private router: Router,
              private service: ApiService,
              public dialog: MatDialog,
              private data: DataService) {
  }

  ngOnInit(): void {
    this.data.isLoading.next(true);
    this.curDate = new Date();
  }

  logout() {
    SessionManager.clearSession();
    this.router.navigate(['login']);
  }

  getInitials(string: string): string {
    let names: any = string?.trim().replace(/_/g, '').split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  openNotificationDialog(): void {
    const iconBellElement = document.getElementById('iconBell');
    if (iconBellElement) {
      const dialogConfig = {
        panelClass: 'custom-dialog-container',
        width:'35%',
        height:'60%',
        position: {
          top: `3%`,
          left: `60%`,
          right:`25%`
        }
      };

      const dialogRef =this.dialog.open(UserNotificationsComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(() => {});
    }
  }

  toggleSidebar(): void {
    const aside = document.getElementById('side-bar');
    const mainContentid = document.getElementById('main-contentid');
    const header = document.getElementById('header');

    if (this.toggle) {
      aside ? aside.className = 'left-sidebar active' : null;
      mainContentid ? mainContentid.className = 'main-content-active' : null;
      header ? header.style.paddingLeft = '100px' : null;
      this.toggle = !this.toggle
      this.sidebarClass.emit(true);
    } else {
      aside ? aside.className = 'left-sidebar' : null;
      mainContentid ? mainContentid.className = 'main-content' : null;
      header ? header.style.paddingLeft = '250px' : null;
      this.toggle = !this.toggle;
      this.sidebarClass.emit(false);
    }

  }

}
