import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {AdminAuthGuard} from "./auth/admin-auth.guard";
import {UserAuthGuard} from "./auth/user-auth.guard";
import {StartContinueAppraisalComponent} from "./admin/start-continue-appraisal/start-continue-appraisal.component";
import {KpiDetailComponent} from "./admin/kpi-detail/kpi-detail.component";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin',
    canMatch: [AdminAuthGuard],
    loadChildren: () => import('./admin/admin-content.module').then(m => m.AdminContentModule),
  },
  {
    path: 'user',
    canMatch: [UserAuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  // Add the new route for Start/Continue Appraisal
  // {
  //   path: 'startContinueAppraisal/:employeeId',
  //   component: StartContinueAppraisalComponent
  // },  {
  //   path: 'kpiDetails/:employeeId',
  //   component: KpiDetailComponent
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
