import { CandidateDetail } from './candidate';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { GroupedCandidates } from 'src/app/admin/candidates/Candidate-Active-Board/interface/lane';
import { CandidateStatus } from 'src/app/admin/candidates/Candidate-Active-Board/interface/issue-status';

@Injectable()
export class DraggingService {

  constructor(private ngZone: NgZone) {}

  private _candidatesSubject = new BehaviorSubject<CandidateDetail[]>([]);
  candidatesSubject$ = this._candidatesSubject.asObservable();

  public groudpedCandidatesSubject$ = new BehaviorSubject<GroupedCandidates[]>([]);

  public currentStatus$ = new BehaviorSubject<string | null>(null);

  private _dragging = new BehaviorSubject<boolean>(false);
  dragging$ = this._dragging.asObservable();

  private _currentLaneId = new BehaviorSubject<string>('');
  currentLaneId$ = this._currentLaneId.asObservable();

  private _focusedKey = new BehaviorSubject<number | null>(null);
  focusedKey$ = this._focusedKey.asObservable();

  public candidateFormLoaded$ = new Subject<boolean>();

  setDragging(value: boolean): void {
    this._dragging.next(value);
  }

  setCurrentLaneId(id: string): void {
    this._currentLaneId.next(id);
  }

  setFocus(candidateId: number | null) : void {
    this._focusedKey.next(candidateId);
  }

  cancelDraggingOperation(): void {
    this.currentStatus$.next(null);
    this.setDragging(false);
    this.ngZone.run(() => {
      this.simulateMouseEvent('mouseup');
    });
  }

  private simulateMouseEvent(eventType: string): void {
    const event = new MouseEvent(eventType, {
      bubbles: true,
      cancelable: true
    });
    document.dispatchEvent(event);
  }

  updateAllCandidate(candidateId: number, newStatus: string, newName?: string, newEmail?: string, designation?: string, experience?: number, commentCount?: number | null, newMobile?: string) : void {
    const toUpdateStatus = (newStatus === CandidateStatus.Selected || newStatus === CandidateStatus.Rejected) ? newStatus : undefined;
    newStatus = toUpdateStatus ? CandidateStatus.Done : newStatus;
    const currentGroupedCandidates = this.groudpedCandidatesSubject$.getValue();
    const group = currentGroupedCandidates.find(group => group.title === newStatus);
    if (!group?.candidates) {
      console.warn(`No group found with status: ${newStatus}`);
      return;
    }
    const candidate = group.candidates.find(candidate => candidate.id === candidateId);
    if (!candidate) return;
    candidate.email = newEmail ?? candidate.email;
    candidate.name = newName ?? candidate.name;
    candidate.designation = designation ?? candidate.designation;
    candidate.experience = experience ?? candidate.experience;
    candidate.commentCount = commentCount ?? candidate.commentCount;
    candidate.mobile = newMobile ?? candidate.mobile;
    const statusValue = CandidateStatus[newStatus as keyof typeof CandidateStatus];
    if (!statusValue) throw new Error(`Invalid status value: ${newStatus}`);
    candidate.status = newStatus === CandidateStatus.Done && toUpdateStatus ? toUpdateStatus : statusValue;
    this.groudpedCandidatesSubject$.next(currentGroupedCandidates);
  }

  updateCandidateStatus(candidateId: number | string, newStatus: string) : void {
    const currentGroupedCandidates = this.groudpedCandidatesSubject$.getValue();
    let currentGroup;
    let candidateToUpdate;
    for (const group of currentGroupedCandidates) {
      const candidate = group.candidates && group.candidates.find(candidate => candidate.id === candidateId);
      if (candidate) {
        currentGroup = group;
        candidateToUpdate = candidate;
        break;
      }
    }
    if (currentGroup && candidateToUpdate) {
      const updatedStatus = CandidateStatus[newStatus as keyof typeof CandidateStatus];
      if (!updatedStatus) {
        throw new Error(`Invalid status value: ${newStatus}`);
      }
      candidateToUpdate.status = updatedStatus;
      currentGroup.candidates = currentGroup.candidates && currentGroup.candidates.filter(candidate => candidate.id !== candidateId);
      newStatus = (newStatus === CandidateStatus.Selected || newStatus === CandidateStatus.Rejected) ? CandidateStatus.Done : newStatus;
      const newGroup = currentGroupedCandidates.find(group => group.title === newStatus);
      if (!newGroup) {
        throw new Error(`No group found with status: ${newStatus}`);
      }
      newGroup.candidates && newGroup.candidates.push(candidateToUpdate);
      this.groudpedCandidatesSubject$.next(currentGroupedCandidates);
    } else {
      console.error(`No candidate found with id: ${candidateId}`);
    }
  }

  removeCandidate(candidateId: number | string, currentStatus: string) : void {
    const currentGroupedCandidates = this.groudpedCandidatesSubject$.getValue();
    if(currentStatus === CandidateStatus.Selected || currentStatus === CandidateStatus.Rejected) {
      currentStatus = CandidateStatus.Done;
    }
    const group = currentGroupedCandidates.find(group => group.title === currentStatus);
    if (group && group.candidates) {
      const candidateIndex = group.candidates.findIndex(candidate => candidate.id === candidateId);
      if (candidateIndex !== -1) {
        group.candidates.splice(candidateIndex, 1);
        this.groudpedCandidatesSubject$.next(currentGroupedCandidates);
      }
    } else {
      console.error(`No group found with status: ${currentStatus}`);
    }
  }

}
