<div class="comments">
  <form [formGroup]="commentForm" (submit)="onSubmit()" ngNativeValidate>
    <div>
      <ng-container *ngIf="!expandTextarea">
        <input class="comment-form-input w-100 mb-2 form-control" type="text" placeholder="Write Comment"
               formControlName="text" minlength="7"
               required (click)="focusTextarea()">
      </ng-container>
      <ng-container *ngIf="expandTextarea">
        <textarea #commentTextarea class="comment-form-textarea form-control" placeholder="Write Comment"
                  formControlName="text" minlength="7" required></textarea>
      </ng-container>
    </div>

    <div class="d-flex justify-content-end mt-3" *ngIf="expandTextarea">
      <button type="button" class="comment-form-button comment-form-cancel-button" (click)="cancelButton()">Cancel
      </button>
      <button class="comment-form-button comment-add-button ms-3" type="submit">Add Comment</button>
    </div>
  </form>
  <!--  <hr />-->
  <div class="comments-container my-2 mt-4">
    <div *ngFor="let comment of comments" class="mb-2">
      <div class="d-flex align-items-start">
        <div class="employee_img me-2">
          <img class="imageAvatar"
               src="{{ (comment?.user && comment.user.profilePicture) ? comment.user.profilePicture : 'assets/profileimage.png' }}">

        </div>
        <div class="profile-usertitle w-100">
          <div class="sidebar-userpic-name" style="cursor: pointer;font-weight: 500">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <p class="mb-1">{{ comment?.user?.name }}</p>
                <p class="mb-1"
                   style="font-size: 10px;margin-left: 8px">{{ comment.createdDate | date: 'dd/MM/YYYY' }}</p>
              </div>
              <div *ngIf="userIdOfCurrentUser === (comment?.user?.id)">
                <button id="more-btn dropdownMenuButton1" class="more-btn" type="button" (click)="toggleDropdown()">
                  <span class="more-dot"></span>
                  <span class="more-dot"></span>
                  <span class="more-dot"></span>
                </button>
                <ul class="dropdown-menu" [ngClass]="{ 'show': isDropdownOpen }" aria-labelledby="more-btn">
                  <li>
                    <a class="dropdown-item" (click)="editFeedbackComment(comment.id)">
                      Edit comment
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" (click)="deleteComment('delete', comment.id)">
                      Delete comment
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="comment_desc" *ngIf="!editFeedback||showCommentText && comment.id!==selectedCommentId"
                 [innerHTML]="comment.textHtml"
                 style="font-size: 12px"></div>
            <form [formGroup]="commentEditForm">
              <div *ngIf="editFeedback && comment.id == selectedCommentId"><textarea [ngModel]="comment?.text"
                                                                                     class="comment-form-textarea"
                                                                                     placeholder="Write Comment"
                                                                                     formControlName="text"
                                                                                     minlength="7" required></textarea>
                <span class="text-primary cursor-pointer"
                      (click)="editComment(comment.id)">Done</span>
                <span class="text-primary cursor-pointer comment-form-button2 comment-form-cancel-button"
                      (click)="cancelEditComment()">Cancel</span></div>
            </form>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
