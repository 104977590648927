
<div class="container modal-popUp-content text-center position-relative">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content>{{data.message}}  ?</mat-dialog-content>
  <mat-dialog-actions class="actions-container">

    <button mat-raised-button *ngIf="data.type==='confirmation'" (click)="close(false)">No </button>
    <button mat-raised-button *ngIf="data.type==='confirmation'" mat-dialog-close (click)="close(true)">Yes</button>
    <button mat-raised-button *ngIf="data.type==='Delete'" (click)="close(false)">Cancel</button>
    <button mat-raised-button *ngIf="data.type==='Delete'" (click)="close(true)">Delete</button>
  </mat-dialog-actions>
</div>
