import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, filter, finalize, Observable, switchAll, switchMap, take, throwError} from "rxjs";
import {Router} from "@angular/router";
import {LoginService} from "./login.service";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {SessionManager} from "../Shared/SessionManager";
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{
  isRefreshing=false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(private router:Router, private service: LoginService,private data: DataService) { }

  intercept(request: HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
    let authReq=request;
    this.data.isLoading.next(true);
    const url:String =request.url;

    if(url.includes('login')|| url.includes('oauth/token')){
      return next.handle(request);
    }
    const token=SessionManager.getToken();
    if(token){
      request=this.addToken(request,token);
    }
    return next.handle(request).pipe(catchError((err: HttpErrorResponse)=>{
      if(err instanceof HttpErrorResponse && err.status===401){
        return this.handle401Error(authReq,next);
      }else if (err instanceof HttpErrorResponse && err.status===403){
        window.location.reload();
        localStorage.clear();
        console.log("====refresh token is given error=======");
        this.isRefreshing=false;
        this.data.isLoading.next(false);
        this.router.navigate(['/login']);
      }
      finalize(
        ()=>{
          this.data.isLoading.next(false);

        })
      return throwError(()=>err);
    }));
  }

  private handle401Error(reg:HttpRequest<any>,next:HttpHandler){
    if(!this.isRefreshing){
      this.isRefreshing=true;
      this.refreshTokenSubject.next(null);
      //this.addBasicAuthToken(reg);
      const refreshToken: any=SessionManager.getRefreshToken();
      const data=new FormData();
      data.set("refresh_token",refreshToken);
      data.set("grant_type","refresh_token");
      if(refreshToken)
        return this.service.refreshToken(refreshToken,data).pipe(
          switchMap((value, index)=>{
            this.isRefreshing=false;
            console.log('value' , value)
            let newAccessToken=value.access_token;
            //let newRefreshToken=value.refresh_token;
            SessionManager.updateSession(newAccessToken,refreshToken);
            console.log("if login token is expired then refresh token will be generated")
            this.refreshTokenSubject.next(newAccessToken);
            this.data.isLoading.next(false);
            return next.handle(this.addToken(reg,newAccessToken));
          }));
      console.log('Send Refresh APi call')
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token )=> next.handle(this.addToken(reg,token)))
    );
  }

  private addBasicAuthToken(request:HttpRequest<any>){
    return request.clone({
      setHeaders:{
        'authorization':`Basic YWNtZTphY21lc2VjcmV0`
      }
    });
  }

  private addToken(request:HttpRequest<any>,token:any){
    return request.clone({
      setHeaders:{
        'authorization':`Bearer ${token}`
      }
    });
  }
}
