export enum CandidateStatus {
    Todo = 'Todo',
    InProgress = 'InProgress',
    OnHold = 'OnHold',
    Rejected = 'Rejected',
    Selected = 'Selected',
    Done = 'Done',
}

export interface ClickedCandidate {
    candidateId: number;
    isOpen: boolean;
}

