import {NgModule} from '@angular/core';
import {
  Camera,
  Heart,
  Github,
  Home,
  Activity,
  PlusCircle,
  Calendar,
  Users,
  CheckSquare,
  BarChart2,
  Edit,
  Edit3,
  Trash2,
  X,
  Bell,
  Menu,
  MessageCircle,
  Eye,
  EyeOff, MessageSquare, Codesandbox, CornerDownRight, ChevronLeft, ChevronRight, Download, ShoppingBag, Image,
} from 'angular-feather/icons';
import {FeatherModule} from "angular-feather";

const icons = {
  Camera,
  Heart,
  Github,
  Home,
  Activity,
  PlusCircle,
  Calendar,
  Bell,
  Users,
  CheckSquare,
  BarChart2,
  Edit,
  Edit3,
  Trash2,
  Menu,
  X,
  MessageCircle,
  Eye,
  EyeOff,
  MessageSquare,
  Codesandbox,
  CornerDownRight,
  ChevronLeft,
  ChevronRight,
  Download,
  ShoppingBag, Image,
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule {

}
