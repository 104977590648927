<div #insideElement class="d-block position-relative">
  <div class="w-100 d-flex justify-content-between customAutoComplete align-items-center">
    <label for="{{label}}" class="m-0 position-absolute">{{label}} <span *ngIf="required" class="error text-danger">*</span></label>
    <div [ngClass]="{'selectedItem' : !required && selectedItem}" class="d-flex align-items-center border-0 cursor-pointer">
      {{selectedItem ?? '- Select Manager -'}}
      <ng-container *ngIf="!required">
        <mat-icon *ngIf="selectedItem" id="closeButton" class="ms-1">cancel</mat-icon>
      </ng-container>
    </div>
    <mat-icon [ngClass]="{ 'activeTransform': openSuggestionBox }" >expand_more</mat-icon>
  </div>
  <div *ngIf="openSuggestionBox">
    <div class="shadow dropDownSuggestionBoxWrapper list-container position-absolute w-100"  >
      <div class="d-flex align-items-center position-relative search_p">
        <mat-icon class="position-absolute searchIcon">search</mat-icon>
        <input #formInput type="text" (keydown)="onKeyDown($event)" class="w-100" placeholder="Search..." [formControl]="formServerCtrl">
        <mat-spinner diameter="20" [ngClass]="dataSpinner ? 'd-block' : 'd-none'" class="position-absolute countrySpinner"></mat-spinner>
        <mat-icon class="position-absolute countrySpinner cursor-pointer"  [ngClass]="!dataSpinner && hasValue && formInput.value.length>0? 'd-block' : 'd-none'" (click)="clearInput()" >close</mat-icon>
      </div>
      <ul class="dropDownSuggestionBox p-0 m-0" [style.max-height.px]="dynamicHieght || 200">
        <ng-container *ngFor="let item of items; let i = index">
          <li class="d-flex justify-content-between align-items-center" [class.selectedCountry]="i === selectedItemIndex" tabindex="1" (click)="customSelectedItem(item)">
            {{item.name ?? item.title}}
          </li>
        </ng-container>
        <ul>
          <li *ngIf="items.length == 0" class="d-flex justify-content-between align-items-center disabled">
            No Item Found
          </li>
        </ul>
      </ul>
    </div>
  </div>
</div>
