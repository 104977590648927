<div class="wrapper col-2">
  <aside class="left-sidebar" id="side-bar">
    <div
      class="sidebar_admin flex-column  min-vh-100 position-relative overflow-hidden h-100 d-flex sidebar-with-footer">
      <div
        class="position-relative flex-column flex-wrap align-content-start justify-content-start align-items-start h-100">
        <div class="simplebar-content-wrapper">
          <div class="simplebar-content p-0">
            <div class="user-sidebar text-center">
              <div class="dropdown">
                <div *ngIf="user.roleName=='employee'" class="user-img">
                  <img src="../../../assets/LogoBold.png">
                </div>
                <div *ngIf="user.roleName=='admin'" class="user-img">
                  <img src="../../../assets/AdminLogo%20.png">
                </div>
                <div class="user-info">
                  <div class="image">
                    <img src="{{this.userprofile==null? '../../../assets/user.jpg': this.userprofile}}"
                         class="user-img-circle img-circle">
                  </div>
                  <div class="profile-usertitle mt-2">
                    <div class="sidebar-userpic-name">{{userData?.name}}</div>
                    <div class="profile-usertitle-job text-capitalize"
                         matTooltip="{{this.empStatus?this.empStatus:'Not Present'}}">{{user.managerStatus == true ? 'MANAGER' : user.roleName}}
                      <span
                        class="badge badge-success {{this.empStatus=='CHECKED_IN'? 'dot-active':'more-dot' && this.empStatus=='CHECKED_OUT'? 'dot-inactive':'more-dot'}}">
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul *ngIf="user.roleName=='admin'"
                class="sidebar-inner_admin navbar-nav d-flex flex-column flex-wrap list-unstyled p-0">
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['/admin/dashboard']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="Home" class="size_20"></i-feather>
                    <span class="nav-text ms-2">DASHBOARD</span>
                  </div>
                </a>
              </li>

              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./employees']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="users" class="size_20"></i-feather>
                    <span class="nav-text ms-2">EMPLOYEES</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./attendance']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="check-square" class="size_20"></i-feather>
                    <span class="nav-text ms-2">ATTENDANCE</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./leaves']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="bar-chart-2" class="size_20"></i-feather>
                    <span class="nav-text ms-2">LEAVES</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./calender']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="calendar" class="size_20"></i-feather>
                    <span class="nav-text ms-2">HOLIDAYS</span>
                  </div>
                </a>
              </li>

              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./notification']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="bell" class="size_20"></i-feather>
                    <span class="nav-text ms-2">NOTICE</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./scrum_attendance']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="check-square" class="size_20"></i-feather>
                    <span class="nav-text ms-2">SCRUM ATTENDANCE</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./attendance_log']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="check-square" class="size_20"></i-feather>
                    <span class="nav-text ms-2">FACE RECOGNITION</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./performance']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="activity" class="size_20"></i-feather>
                    <span class="nav-text ms-2">Performance</span>
                  </div>
                </a>
              </li>
              <li class="nav-item accordion b-0" id="accordionExample">
                <div class="accordion-item border-0">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button side-menu_item_admin" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <i-feather name="users" class="size_20"></i-feather>
                      <span class="nav-text ms-2">CANDIDATES</span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body p-0">
                      <ul class="list-unstyled">
                        <li class="submenu_item" [routerLinkActive]="['active']" [routerLink]="['./candidates']">
                          <a>
                            <div>
                              <i-feather name="bar-chart-2"></i-feather>
                              <span class="nav-text ms-2">Backlog</span>
                            </div>
                          </a>
                        </li>
                        <li class="submenu_item" [routerLinkActive]="['active']" [routerLink]="['./boards']">
                          <a>
                            <div>
                              <i-feather name="eye"></i-feather>
                              <span class="nav-text ms-2">Boards</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['./inventory']" class="side-menu_item_admin nav-link">
                  <div>
                    <i-feather name="shopping-bag"></i-feather>
                    <span class="nav-text ms-2">Inventory</span>
                  </div>
                </a>
              </li>
            </ul>


            <ul *ngIf="user.roleName=='employee'"
                class="sidebar-inner_user navbar-nav d-flex flex-column flex-wrap list-unstyled p-0">
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['/user/dashboard']" class="side-menu_item nav-link">
                  <div>
                    <i-feather name="Home" class="size_20"></i-feather>
                    <span class="nav-text ms-2">DASHBOARD</span>
                  </div>
                </a>
              </li>

              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['/user/user_profile']" class="side-menu_item nav-link">
                  <div>
                    <i-feather name="camera" class="size_20"></i-feather>
                    <span class="nav-text ms-2">PROFILE</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['/user/user_leaves']" class="side-menu_item nav-link">
                  <div>
                    <i-feather name="Activity" class="size_20"></i-feather>
                    <span class="nav-text ms-2">LEAVES</span>
                  </div>
                </a>
              </li>

              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['/user/user_secret']" class="side-menu_item nav-link">
                  <div>
                    <i-feather name="users" class="size_20"></i-feather>
                    <span class="nav-text ms-2">PASSWORD</span>
                  </div>
                </a>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a [routerLink]="['/user/user_performance']" class="side-menu_item nav-link">
                  <div>
                    <i-feather name="activity" class="size_20"></i-feather>
                    <span class="nav-text ms-2">PERFORMANCE</span>
                  </div>
                </a>
              </li>


            </ul>
          </div>
        </div>
      </div>

    </div>
  </aside>
</div>
