import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SessionManager} from "../../Shared/SessionManager";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {EmployeeProfile, User} from "../../Shared/Interfaces";
import {ApiService} from "../../services/api.service";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.scss']
})
export class MenuNavigationComponent implements OnInit{
 userprofile:User[]=[];
  user: EmployeeProfile[] |any ;
  dashboardActive=false;
  userDashboardActive=false;
  employeeActive=false;
  url='';
  empStatus:any;
  @Input() userData: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dataService: DataService,
              private datePipe: DatePipe,
              private apiService : ApiService,
              public dialog: MatDialog,
              public data: DataService,) {}

  ngOnInit(): void {

    this.user=SessionManager.getUser();
    this.userprofile=this.userData.profilePicture;
    this.router.events.subscribe((res:any)=>{
      this.url=res.url;
      if(res instanceof NavigationEnd){
        // console.log('blue2 ',res.url, this.user.roleName)
        // if(res.url == '/admin/dashboard' && this.user.roleName=='admin'){
        //   this.dashboardActive=true;
        // }
        // else if(res.url=='user/dashboard' && this.user.roleName=='employee'){
        //   this.userDashboardActive=true;
        // }
        // else if(res.url== '/modify'){
        //   this.employeeActive=true;
        //   // console.log('blue1 ',this.employeeActive)
        // }
      }

    });
    this.getStatus();
  }

  getStatus(){
    this.apiService.getEmpStatus(this.userData.id).subscribe({
        next: res => {
          this.empStatus = res?.status;
          // console.log(this.empStatus+'emp status')
        },
        error: err => {
          console.log('err',err)
        },
        complete: () => {
          this.dataService.isLoading.next(false);
        }
      });
  }
}

